/* global __lodash__, _, moment, wrapGet, Component */
class CarComponent extends Component {
    static name() {
        return "CarModalComponent";
    }

    static componentName() {
        return "CarModalComponent";
    }

    data() {
        return {
            currentStep:1,
            minStep:1,
            maxStep:4,
            pricesLoaded:false,
            status:'view',
            result: '',
            detailItem:null,
            showDetail:true,
            sucessCreate:false,
            completeDetail:false,
            salesOrder :'',
            orderComment :'',
            orderCampaign:null,
            orderCampaignActive:false,
            deliveryDate:moment().format('YYYY-MM-DD'),
            TaxRegTypes:['Reg.Company', 'Final Consumer', 'IVA No Resp.', 'Exempt', 'Resp. no Insc.', 'Monotributo', 'International Exempt', '', 'Sujeto no Categorizado'],
            errors:[],
            successMessage:"Congratulation, Sales Order #({{$1}}) was created",
            paymentMsg:null,
            paymentIsProcess:false,
            doOnlinePayment:false,
            orderTemplate2:null,
            mpdata:{
                salesorder:null,
                amount:0,
                email:"test@dos.com" //Mail del cliente comprados.
            },
            searchMessage:null,
            tempItemCode:null,
            tempItemQty:1,
            currentPayTerm: null,
            giftCardCode:null
        };
    }

    mounted() {
        return async function () {
            this.discountThrottled = __lodash__.throttle(this.addDiscount,1800, {'trailing': false});
            this.currentPayTerm = this.PayTerm;
            this.$store.dispatch('updateOrderDeliveryData',{'order_PayTerm': this.$store.state.customer.PayTerm});
            this.checkItemInCar();
            this.loadPrices();
            this.calculateOrderTotals();
            this.subscribeEvent('orderItemUpdate',this.calculateOrderTotals);
            this.subscribeEvent('deliveryselected',this.calculateOrderTotals);
            this.subscribeEvent('shipModeChange',this.calculateShipCost);
        };
    }

    updated(){
        return async function (){
            if(!this.$store.state.session && this.$store.state.appState == 'ready') {
                this.$store.state.redirectAfterLogin = "/badges";
                this.$router.push('/login');
            }
        };
    }

    goToShop(){
        if(!this.$store.getters.getItems || this.$store.getters.getItems.length == 0) 
            this.$router.push('/home');
        else
            this.$router.go(-1);
    }

    checkStepConditions() {
        if(this.$store.getters.getSetting.ControlStockForSales) {
            for(let soItem of this.getOrderItems) {
                const maxQty = this.$store.getters.getMaxStockByCode(soItem.fields.ArtCode);
                if(soItem.fields.Qty > maxQty) {
                    this.alertShowMessage(["{{$1}} only available {{$2}}", soItem.fields.Name, maxQty]);
                    return false;
                }
            }
        }
        switch(this.currentStep){
            case 1: {
                break;
            }
            case 2: {
                if (this.$store.state.CustomerAddresses.length > 0  && !this.$store.state.order_DelAddressCode) {
                    this.$store.dispatch('showNotificacion',{title:this.tr('Delivery Address'), message: this.tr("Select a Delivery Address or adding a new"),type: 'info'});
                    return false;
                }
                if (this.$store.getters.getShipModes.length > 0  && !this.$store.state.order_ShipMode) {
                    this.$store.dispatch('showNotificacion', {
                        title: this.tr('Ship Mode'),
                        message: this.tr(["Please select a {{$1}}", this.tr('Ship Mode')]),
                        type: 'info'
                    });
                    return false;
                }
                if (this.$store.state.requireCenterCost && (!this.$store.state.order_CenterCost || this.$store.state.order_CenterCost === '')) {
                    this.$store.dispatch('showNotificacion', {
                        title: this.tr('Cost Center'),
                        message: this.tr(["Please select a {{$1}}", this.tr('Cost Center')]),
                        type: 'info'
                    });
                    return false;
                }
                let checkPaymentDay = false;
                checkPaymentDay = Object.values(this.$store.getters.getDaysByZone).reduce((x, y) => x || y, checkPaymentDay);
                if (checkPaymentDay && (!this.$store.state.order_DelDay || this.$store.state.order_DelDay === '')) {
                    this.$store.dispatch('showNotificacion', {
                        title: this.tr('Delivery Day'),
                        message: this.tr(["Please select a {{$1}}", this.tr('Delivery Day')]),
                        type: 'info'
                    });
                    return false;
                }
                break;
            }
            case 3: {
                if (!this.$store.state.order_PayTerm) {
                    this.$store.dispatch('showNotificacion',{title:this.tr('Pay Term'), message: this.tr("Select a Pay Term"),type: 'info'});
                    return false;
                }
                break;
            }
        }
        return true;
    }

    payAndClose(e) {
        let res = this.checkStepConditions();
        if(!res)
            return false;
        if(this.$store.state.order_DelAddressCode){
            this.nextStep();
            this.status = "loading";
            let payload = this.getOrderPayload();
            this.$store.dispatch('toogleLockScreen',null);
            this.$store.dispatch('createSaleOrder',payload).then((result)=>{
                this.$store.dispatch('toogleLockScreen',null);
                if(!result.error)
                    result.error = [];
                this.result = result;
                if (result.success) {
                    this.gtmTrackEcommercePurchase(this.result.so);
                    this.status = "result";
                    let mpdata = {};
                    mpdata.email = "";
                    mpdata.salesorder = this.result.so.fields.SerNr;
                    mpdata.amount = this.result.so.fields.Total;
                    this.doOnlinePayment = this.$store.getters.getOnlinePayTerms.indexOf(this.result.so.fields.PayTerm) != -1 && this.result.so.fields.OrderStatus != this.$store.getters.getSetting.PreAutorizationStatus;
                    this.mpdata = mpdata;
                    this.$store.commit('clearItemToCar', 'result');
                }
                else {
                    this.status = "failure";
                    this.$store.commit('clearItemToCar', 'failure');
                }
            });
        }
        else{
            this.$store.dispatch('showNotificacion',{title:this.tr('Delivery Address'), message: this.tr("Select a Delivery Address or adding a new"),type: 'info'});
        }
    }

    allowDecimal(item){
        let storeItem = this.$store.getters.getItemByCode(item.fields.ArtCode);
        if(storeItem && storeItem.OnlyIntegerValues == 1)
            return 1;
        return "any";
    }

    qtyFormat(item){
        if(this.allowDecimal(item) == 'any')
            return parseFloat(item.fields.Qty);
        return parseInt(item.fields.Qty);
    }

    getMinQtyAviable(item){
        if(this.allowDecimal(item) == 'any')
            return 0.3;
        return 1;
    }

    addItemToCar(){
        let quickShopItemCode = document.getElementById("qShopItemCode");
        const tempQty  = parseInt(this.tempItemQty.toString());
        if(!this.tempItemCode) {
            this.searchMessage = this.tr(["NOTEMPTY",this.tr('Item Code')]);
        } else {
            if(tempQty <= 0)
                this.searchMessage = this.tr("The Item Qty must be greater than zero");
        }
        if(this.tempItemCode && tempQty > 0) {
            let item = this.$store.getters.getItemByCode(this.tempItemCode);
            if(item) {
                let newItemQty =  parseInt(this.tempItemQty);
                const qtyInCar = this.$store.getters.getItemAmountInCar(this.tempItemCode);
                if(this.$store.getters.getSetting.ControlStockForSales) {
                    const maxQty = this.$store.getters.getMaxStockByCode(this.tempItemCode);
                    const available = Math.max(0, maxQty - qtyInCar);
                    if(maxQty == 0 && qtyInCar > 0) {
                        newItemQty = 0;
                        this.$store.dispatch('changeCartQty', {itemCode: this.tempItemCode, action: 'delete'});
                        if(!quickShopItemCode)
                            this.alertShowMessage(["This item {{$1}} is not longer available", item.Name]);
                        else
                            this.searchMessage = this.tr(["This item {{$1}} is not longer available", item.Name]);
                    } else {
                        if (available == 0) {
                            newItemQty = available;
                            if(!quickShopItemCode)
                                this.alertShowMessage(["This item {{$1}} is not longer available", item.Name]);
                            else
                                this.searchMessage = this.tr(["This item {{$1}} is not longer available", item.Name]);
                        } else {
                            if (newItemQty > available) {
                                if(!quickShopItemCode)
                                    this.alertShowMessage(["{{$1}} only available {{$2}}", item.Name, available]);
                                else
                                    this.searchMessage = this.tr(["{{$1}} only available {{$2}}", item.Name, available]);
                                newItemQty = 0;
                            }
                        }
                    }
                }

                if(newItemQty > 0) {
                    if(qtyInCar) {
                        this.$store.dispatch('changeCartQty',{itemCode:this.tempItemCode, qty: newItemQty + qtyInCar, action:'update'}).then(actionRes=> {
                            this.calculateOrderTotals();
                            this.tempItemCode = null;
                            this.tempItemQty = 1;
                            if(quickShopItemCode) {
                                $('#qShopItemCode').trigger('focus');
                            }
                        });
                    } else {
                        item.CarQty = newItemQty;
                        this.$store.dispatch('ItemComponentAddToCar',item).then(actionRes=> {
                            this.calculateOrderTotals();
                            this.tempItemCode = null;
                            this.tempItemQty = 1;
                            if(quickShopItemCode) {
                                $('#qShopItemCode').trigger('focus');
                            }
                        });
                    }
                    this.$store.dispatch('showNotificacion',{title:`Agregaste ${newItemQty} ${item.carQty > 1 ? 'artículos' : 'artículo'}  a tu carrito`, message: `${item.Name}`,type: 'info'});
                }
            } else {
                if(!quickShopItemCode)
                    this.$store.dispatch('showNotificacion', {
                        title: this.tr('Not Found'),
                        message: this.tr(["Item not found ({{$1}})", this.tempItemCode]),
                        type: 'info'
                    });
                else 
                    this.searchMessage = this.tr(["Item not found ({{$1}})", this.tempItemCode]);
            }
        }
        
        if(quickShopItemCode)
            $('#qShopItemCode').trigger('focus');

        if(this.searchMessage) {
            let self = this;
            setTimeout(()=>{
                self.searchMessage = null;
            },8000);
        }
    }

    alertShowMessage(alertMsg){
        this.$store.dispatch('showNotificacion',{title:this.tr(`Information`), message: alertMsg,type: 'warning'});
    }

    checkItemInCar(){

        for (let carItem of this.$store.getters.getItemCar) {
            if(!carItem.id)
                carItem.id = carItem.item.ArtCode;
            if(!carItem.item.Code)
                carItem.item.Code = carItem.item.ArtCode;
            if(this.$store.getters.getItemByCode(carItem.id.toString()))
                if(carItem.item)
                    carItem.item.carCant = carItem.cant;
            carItem.message = null;

        }

    }

    getMaxStockByCode(itemCode){
        let giftCardItem = this.$store.getters.getGiftCardItem;
        if(giftCardItem && giftCardItem.fields.Code == itemCode)
            return -1;
        if(this.$store.getters.getSetting.ControlStockForSales) {
            let itemInStore = this.$store.state.items_store.get(itemCode);
            if(itemInStore) {
                if (itemInStore.Reserved)
                    return itemInStore.Qty - itemInStore.Reserved;
                return itemInStore.Qty;
            }
        }
        return this.$store.getters.getMaxQtyItem;
    }

    loadPrices(){
        //console.log('items in home call 111');
        let itemCodes = new Map();
        let errors = [];
        let clearCodes = [];
        let sessionCartItems = [];
        //console.log('ITems Home 333',this.$store.getters.getHomeItems)
        for (let carItem of this.itemsSelected){
            if(!carItem.id)
                carItem.id = carItem.item.ArtCode;
            if(!carItem.item.Code)
                carItem.item.Code = carItem.item.ArtCode;
            if (!itemCodes.has(carItem.id)) {
                let tempItem = this.$store.getters.getItemByCode(carItem.id.toString());
                //console.log('Cart tempItem', tempItem);
                if (tempItem) {
                    sessionCartItems.push(carItem);
                    if(tempItem.isCalculate == false)
                        itemCodes.set(tempItem.Code.toString(), tempItem);
                }
                else {
                    carItem.cant = 0;
                    let error_msg = [`Item {{$1}} was removed from car`, carItem.item  && carItem.item.Name ? carItem.item.Name : carItem.id];
                    errors.push(error_msg);
                    this.deleteItemFromCar(carItem.id);
                }
            }
        }
        this.$store.commit('updateSessionCar',sessionCartItems);
        let self = this;
        if(!this.pricesLoaded && Array.from(itemCodes.values()).length > 0){
            this.$store.dispatch("updatePrices", Array.from(itemCodes.values()))
                .then(function (response){
                    self.pricesLoaded = true;
                    self.$store.dispatch('reloadCarPrice',clearCodes);
                    self.errors = errors;
                });
        }
    }

    checkStock(){
        //console.log('items in home call 111');
        let itemCodes = new Map();
        let errors = [];
        let clearCodes = [];
        //console.log('ITems Home 333',this.$store.getters.getHomeItems)
        for (let carItem of this.itemsSelected){
            if (!itemCodes.has(carItem.id)) {
                let tempItem = this.$store.getters.getItemByCode(carItem.id);
                //console.log('Cart tempItem', tempItem);
                if (tempItem) {
                    if(this.$store.getters.getControlStock){
                        if(!tempItem.Qty || tempItem.Qty == 0) {
                            clearCodes.push(carItem.id);
                            let error_msg = `++ ${this.tr('Item')} ${carItem.id}. ${this.tr('ITEM_STOCK_ZERO')}++`;
                            carItem.cant = 0;
                            errors.push(error_msg);
                        }
                        if(tempItem.Qty < carItem.cant)
                            carItem.cant = tempItem.Qty;

                    }
                    else
                        itemCodes.set(carItem.id, tempItem);
                }
                else {
                    clearCodes.push(carItem.id);
                    let error_msg = `** ${this.tr('Item')} ${carItem.id}. ${this.tr('ITEM_FAVORITE_REMOVE')}** `;
                    carItem.cant = 0;
                    errors.push(error_msg);
                }
            }
        }
        return errors.length == 0;
    }

    updateStatusCar() {
        this.status = this.$store.getters.getCarStatus;
    }

    getOrderPayload() {
        let payload = {
            needReload:true,
            DeliveryDate: this.deliveryDate,
            Comment: this.orderComment,
            SalesOrder : this.salesOrder,
            Campaign: this.orderCampaign,
        };
        if(this.orderCampaignActive)
            payload["Campaign"] = this.orderCampaign;
        return payload;
    }

    calculateOrderTotals(){
        this.$store.dispatch('toogleLockScreen',null);
        let payload = this.getOrderPayload();
        this.$store.dispatch('loadOrderTotals',payload).then((result)=>{
            this.$store.dispatch('toogleLockScreen',null);
        });
    }

    updateCarQty(event){
        //debugger;
        let newItemQty = event.target.valueAsNumber;
        if(this._resetBlurInterval)
            clearInterval(this._resetBlurInterval);
        if(event.target.valueAsNumber === event.target.oldvalue || event.target.valueAsNumber === event.target._value)
            return;
        if(isNaN(newItemQty) || newItemQty <= 0) {
            this.$store.dispatch('showNotificacion',{title:this.tr(`Qty`), message:this.tr("Incorrent Qty Value"),type: 'error'});
            return;
        }
        if(_.has(this.orderTemplate, ['fields','Items'])) {
            for (let item of this.orderTemplate.fields.Items) {
                if (item.fields.ArtCode == event.target.id) {
                    let storeItem = this.$store.getters.getItemByCode(item.fields.ArtCode);
                    if (storeItem) {
                        if (this.controlStock) {
                            let storeItem = this.$store.getters.getItemByCode(item.fields.ArtCode);
                            const maxQty = this.$store.getters.getMaxStockByCode(storeItem.Code);
                            if (maxQty == 0){
                                this.alertShowMessage(["This item {{$1}} is not longer available", storeItem.Name]);
                                this.gtmTrackEcommerceRemoveFromCart(storeItem, this.orderTemplate.fields.Currency, item.fields.RowNet);
                                return this.$store.dispatch('changeCartQty', {itemCode: item.fields.ArtCode, action: 'delete'});
                            }
                            if (newItemQty > maxQty) {
                                if(maxQty === 0)
                                    this.alertShowMessage("ITEM_STOCK_ZERO");
                                else
                                    this.alertShowMessage(["The article qty can't be bigger than {{$1}}", maxQty]);
                                newItemQty = maxQty;
                            }
                        } else
                            newItemQty = event.target.valueAsNumber;
                        item.fields.Qty = newItemQty;
                    } else {
                        this.gtmTrackEcommerceRemoveFromCart(storeItem, item.fields.Qty, this.orderTemplate.fields.Currency, item.fields.RowNet);
                        this.$store.dispatch('changeCartQty', {itemCode: item.fields.ArtCode, action: 'delete'});
                    }
                }
            }
            let storeItem = this.$store.getters.getItemByCode(event.target.id);
            let cartItem = this.$store.getters.getItemCar.filter(x=>x.id == event.target.id);
            if(newItemQty > cartItem.cant)
                this.gtmTrackEcommerceAddToCart(storeItem, newItemQty - cartItem.cant, this.orderTemplate.fields.Currency, storeItem.Price * (newItemQty - cartItem.cant));
            else
                this.gtmTrackEcommerceRemoveFromCart(storeItem, cartItem.cant - newItemQty , this.orderTemplate.fields.Currency, storeItem.Price * (cartItem.cant - newItemQty));

            this.$store.dispatch('changeCartQty', {
                itemCode: event.target.id,
                qty: newItemQty,
                action: 'update'
            }).then(actionRes => {
                this.calculateOrderTotals();
            });
        }
    }

    itemQtyIncrement(itemCode){
        let storeItem = this.$store.getters.getItemByCode(itemCode);
        this.gtmTrackEcommerceAddToCart(storeItem, 1, this.orderTemplate.fields.Currency, storeItem.Price);
        this.$store.dispatch('changeCartQty',{itemCode:itemCode,action:'up'}).then(()=>{
            this.calculateOrderTotals();
        });
    }

    itemQtyDecrement(itemCode){
        let storeItem = this.$store.getters.getItemByCode(itemCode);
        this.gtmTrackEcommerceRemoveFromCart(storeItem, 1, this.orderTemplate.fields.Currency, storeItem.Price);
        this.$store.dispatch('changeCartQty',{itemCode:itemCode,action:'down'}).then(()=>{
            this.calculateOrderTotals();
        });
    }

    deleteItemFromCar(itemCode){
        let storeItem = this.$store.getters.getItemByCode(itemCode);
        let cartItem = this.$store.getters.getItemCar.filter(x=>x.id == itemCode);
        if(storeItem)
            this.gtmTrackEcommerceRemoveFromCart(storeItem, this.orderTemplate.fields.Currency, storeItem.Price * cartItem.cant);
        this.$store.dispatch('changeCartQty',{itemCode:itemCode,action:'delete'}).then(()=>{
            this.calculateOrderTotals();
        });
    }

    clearCar(){
        let iter = 1;
        for (let item of this.orderTemplate.fields.Items) {
            let storeItem = this.$store.getters.getItemByCode(item.fields.ArtCode);
            if (storeItem) {
                setTimeout(()=>this.gtmTrackEcommerceRemoveFromCart(storeItem, item.fields.Qty, this.orderTemplate.fields.Currency, item.fields.RowNet), 2000 * iter);
                iter++;
            }
        }
        this.$store.commit('resetItemToCar');
        this.calculateOrderTotals();
    }

    close() {
        this.$store.commit('updateCarStatus','view');
        this.$router.go(-1);
    }

    showItemPrice(item) {
        let price = item.Price;
        if(this.$store.getters.getPriceToShow)
            price = item.RowTotal;
        else{
            if(item.RowNet)
                price = item.RowNet;
        }
        return parseFloat(Math.round(price * 100) / 100).toFixed(2);
    }

    rowPriceTotal(item){
        if(item.cant)
            return parseFloat(parseFloat(item.cant) * parseFloat(item.item.Price) * 100 / 100).toFixed(2);
        else
            return 0;
    }

    showPriceLabel() {
        if(this.$store.getters.getPriceToShow)
            return this.tr('VAT INC');
        else{
            return this.tr('VAT EXC');
        }
    }

    goToStep(newState){
        //console.log('State Control',newState);
        switch(newState){
            case 'detail':
                if(!this.sucessCreate) {
                    break;
                }
                this.status = newState;
                break;
            case 'orderResult':
                if(!this.sucessCreate) {
                    this.status = 'orderResult';
                    break;
                }
                if(!this.completeDetail) {
                    this.status = 'detail';
                    break;
                }
                this.status = newState;
        }
        this.$store.commit('updateCarStatus',this.status);
    }

    viewDetailItem(item){
        //console.log( 'Detail ',item );
        this.detailItem = item;
        if(this.detailItem)
            this.emitEvent('modal-detail-open');
    }

    closeDetail(){
        this.detailItem = null;
    }

    navListItems(step) {
        if(this.currentStep > step && this.currentStep < this.maxStep)
            this.currentStep = step;
        if(this.currentStep === this.maxStep && step === 1)
            this.currentStep = 1;
        return false;
    }

    prevStep(force = false) {
        if(force) {
            if (this.currentStep > this.minStep)
                this.currentStep = this.currentStep - 1;
        }
        else {
            if (this.currentStep < this.maxStep) {
                if (this.currentStep > this.minStep)
                    this.currentStep = this.currentStep - 1;
                else
                    this.currentStep = this.minStep;
            }
        }
    }

    nextStep(e) {
        let res = this.checkStepConditions();
        if(!res)
            return false;
        if(this.currentStep < this.maxStep) {
            if (this.currentStep < this.maxStep)
                this.currentStep = this.currentStep + 1;
            else
                this.currentStep = this.minStep;
        }
    }

    paymentProcess(event){
        //console.log( "Payment Event",event );
        this.paymentMsg = [];
        this.$store.dispatch('toogleLockScreen', "");
        //this.handleErrors(event);
        //this.load_i = false;
        if(event.actionResult && event.paymentResult.ok) {
            this.doOnlinePayment = false;
            this.paymentIsProcess = true;
            this.paymentMsg = null;
            this.$store.dispatch("approvedOrder",this.result.so.fields.SerNr);
        }
        else{
            this.doOnlinePayment = true;
            if(event.cause)
                this.paymentMsg = event.cause;
            if(event.paymentResult && event.paymentResult.message) {
                this.paymentMsg = event.paymentResult.message;
            }
            if (event.error){
                this.error = true;
                this.paymentMsg = event.cause[0].description;
            }
            if (event.hasOwnProperty("paymentResult")){
                this.error = true;
                this.paymentMsg = event.paymentResult.message;
            }
            window.scrollTo(0,0);
        }
    }

    paymentInit(){
        this.$store.dispatch('toogleLockScreen',this.tr('Payment Processing'));
    }

    updateSelectValues(updateValue){
        let eventObj = {};
        let value =  event.target.value;
        eventObj[`order_${updateValue}`] = value;
        if (updateValue === "PayTerm") 
            this.currentPayTerm = value;
        this.$store.dispatch('updateOrderDeliveryData',eventObj);
    }

    updateSelectLinktoValues(updateValue, data){
        let eventObj = {};
        eventObj[`order_${updateValue}`] = data.value;
        if (updateValue === "PayTerm") 
            this.currentPayTerm = data.value;
        
        this.$store.dispatch('updateOrderDeliveryData',eventObj);
    }

    getPayTermObj(){
        return this.$store.state.setting.PayTermRow.find(row => row.fields.PayTermCode === this.currentPayTerm);
    }

    async addDiscount(){
        this.$store.dispatch('toogleLockScreen',null);
        let res = {data: {success:true, error:""}};
        let orderCampaign = this.orderCampaign;
        if(this.$store.getters.getSetting.UseDiscountNotCapitalice)
            orderCampaign = orderCampaign.toLowerCase();
        if(this.orderCampaign)
            res = await wrapGet(`/${this.$store.getters.getSetting.PathURL}/api/checkCoupon/` + orderCampaign);
        this.orderCampaignActive = res.data.success;
        if(res.data.success) {
            let payload = this.getOrderPayload();
            payload.Campaign =  orderCampaign;
            await this.$store.dispatch('loadOrderTotals', payload);
            if(this.orderCampaign && this.getDiscountCoupon)
                this.$store.dispatch('showNotificacion',{title:`Coupon`, message: ["Discount Applied of $ {{$1}}",this.getDiscountCoupon] ,type: 'info'});
            else
                this.$store.dispatch('showNotificacion',{title:`Coupon`, message: "Coupon does not meet the requirement to apply" ,type: 'warning'});
        }
        else {
            this.$store.dispatch('showNotificacion',{title:`Coupon`, message: res.data.error,type: 'warning'});
        }
        this.$store.dispatch('toogleLockScreen',null);
    }

    async calculateShipCost(shipModeCode){
        // console.log("CHANGE SHIPCODE",shipModeCode);
        if(shipModeCode) {
            let eventObj = {};
            eventObj[`order_ShipMode`] = shipModeCode;
            this.$store.dispatch('toogleLockScreen', null);
            await this.$store.dispatch('updateOrderDeliveryData', eventObj);
            let payload = this.getOrderPayload();
            payload.ShipMode = shipModeCode;
            await this.$store.dispatch('loadOrderTotals', payload);
            this.$store.dispatch('toogleLockScreen', null);
        }
    }

    async calculateDiscount(){
        this.discountThrottled();
    }

    getPayTermSurchargeLable() {
        const payTermCode = this.orderTemplate.fields.PayTerm;
        return this.tr(['Surcharge {{$1}}', this.getPayTermName(payTermCode)]);
    }

    getPayTermName(payTermCode) {
        const payTermRow = this.$store.getters.getSetting.PayTermRow.filter(x=>x.fields.PayTermCode === payTermCode);
        if(payTermRow.length > 0)
            return payTermRow[0].fields.PayTermName;
        return payTermCode;
    }

    addGiftcard(){
        this.$store.dispatch('ItemGiftcardAddToCar',this.giftCardCode);
    }

    getOrderField(fieldName, defaultValue = null) {
        if(this.orderTemplate.fields)
            return this.orderTemplate.fields[fieldName];
        return defaultValue ? defaultValue : null;
    }

    autoResetBlur() {
        this._resetBlurInterval = setInterval(()=>document.activeElement.blur(), 4000);
    }

    getMethods() {
        return {
            autoResetBlur: this.autoResetBlur,
            getOrderPayload: this.getOrderPayload,
            getOrderField: this.getOrderField,
            goToShop: this.goToShop,
            getPayTermName: this.getPayTermName,
            getPayTermSurchargeLable:this.getPayTermSurchargeLable,
            calculateShipCost:this.calculateShipCost,
            calculateDiscount:this.calculateDiscount,
            addDiscount:this.addDiscount,
            checkStepConditions:this.checkStepConditions,
            payAndClose: this.payAndClose,
            allowDecimal:this.allowDecimal,
            qtyFormat:this.qtyFormat,
            getMinQtyAviable:this.getMinQtyAviable,
            addItemToCar:this.addItemToCar,
            alertShowMessage:this.alertShowMessage,
            checkItemInCar:this.checkItemInCar,
            getMaxStockByCode:this.getMaxStockByCode,
            loadPrices:this.loadPrices,
            checkStock:this.checkStock,
            updateStatusCar:this.updateStatusCar,
            calculateOrderTotals:this.calculateOrderTotals,
            updateCarQty:this.updateCarQty,
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            deleteItemFromCar:this.deleteItemFromCar,
            clearCar:this.clearCar,
            close:this.close,
            showItemPrice:this.showItemPrice,
            rowPriceTotal:this.rowPriceTotal,
            showPriceLabel:this.showPriceLabel,
            goToStep:this.goToStep,
            viewDetailItem:this.viewDetailItem,
            closeDetail:this.closeDetail,
            navListItems:this.navListItems,
            prevStep:this.prevStep,
            nextStep:this.nextStep,
            paymentProcess:this.paymentProcess,
            paymentInit:this.paymentInit,
            updateSelectValues:this.updateSelectValues,
            updateSelectLinktoValues:this.updateSelectLinktoValues,
            getPayTermObj: this.getPayTermObj,
            addGiftcard:this.addGiftcard
        };
    }

    getComputed() {
        return {
            getPayTermSurcharge:function () {
                if(this.orderTemplate && this.orderTemplate.fields && this.payTermSurchargeItemCode){
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let surcharguesItemCodes = this.payTermSurchargeItemCode.split(",");
                    let surchageCostItem = __lodash__.find(items,function (cost){
                        return surcharguesItemCodes.indexOf(cost.fields.ArtCode) !== -1;
                    });
                    if(surchageCostItem) {
                        if (this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(surchageCostItem.fields.VATPrice, 2);
                        else
                            return this.number_format(surchageCostItem.fields.Price, 2);
                    }
                }
                return null;
            },
            payTermSurchargeItemCode:function (){
                return this.$store.getters.getPayTermSurchargeItemCode;
            },
            payTerms(){
                let payterms = [];
                for(let payT of this.$store.state.setting.PayTermRow) {
                    if(payT.fields.ShipModes){
                        if(this.orderTemplate && this.orderTemplate.fields.ShipMode){
                            if(payT.fields.ShipModes.split(",").indexOf(this.orderTemplate.fields.ShipMode) !== -1)
                                payterms.push(payT);
                        }
                    }
                    else
                        payterms.push(payT);
                }
                return payterms;
            },
            discountItemCode:function (){
                return this.$store.getters.getDiscountItemCode;
            },
            shipItemCode:function (){
                return this.$store.getters.getShipItemCode;
            },
            getPaytermSurcharge: function (){
                if(this.orderTemplate && this.orderTemplate.fields.Items && this.payTermSurchargeItemCode){
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let superSurchargeItemCode  = this.discountItemCode.split(",");
                    let chargeItem = __lodash__.find(items,function (discount) {
                        return superSurchargeItemCode.indexOf(discount.fields.ArtCode) !== -1;
                    });
                    if(chargeItem){
                        if(this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(chargeItem.fields.VATPrice,2);
                        else
                            return this.number_format(chargeItem.fields.Price,2);
                    }
                }
                return null;
            },
            getDiscountCoupon:function (){
                if(_.has(this.orderTemplate, ['fields','Items']) && this.discountItemCode){
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let discountItemCode  = this.discountItemCode.split(",");
                    let discountItem = __lodash__.find(items,function (discount){
                        return discountItemCode.indexOf(discount.fields.ArtCode) !== -1;
                    });
                    if(discountItem){
                        if(this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(discountItem.fields.VATPrice,2);
                        else
                            return this.number_format(discountItem.fields.Price,2);
                    }
                }
                return null;
            },
            getShipCost: function () {
                if(_.has(this.orderTemplate, ['fields','Items']) && this.shipItemCode){
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let shipModeCodes = this.shipItemCode.split(",");
                    let shipCostItem = __lodash__.find(items,function (cost){
                        return shipModeCodes.indexOf(cost.fields.ArtCode) !== -1;
                    });
                    if(shipCostItem) {
                        if (this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(shipCostItem.fields.VATPrice, 2);
                        else
                            return this.number_format(shipCostItem.fields.Price, 2);
                    }
                }
                return null;
            },
            getOrderItems:function () {
                if (_.has(this.orderTemplate, ['fields','Items'])) {
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    if(this.$store.getters.getSetting.HideComponentsOfCompoundItems) {
                        let compoundItems = [];
                        for (let item of items) {
                            let rec = this.$store.getters.getItemByCode(item.fields.ArtCode);
                            if(rec && rec.ItemType === 3) {
                                compoundItems.push(item.fields.ArtCode);
                            } else if(rec && rec.ItemType !== 3 && !item.Recipe)
                                compoundItems.push(item.fields.ArtCode);
                        }
                        let filteredItems = items.filter(x => compoundItems.includes(x.fields.ArtCode)); 
                        this.orderTemplate.fields.Items = filteredItems;
                        items = filteredItems;
                    }
                    let shipModeCodes = this.shipItemCode.split(",");
                    let discountItemCode  = this.discountItemCode.split(",");
                    let payterSuerchargeItemCode  = this.payTermSurchargeItemCode.split(",");
                    __lodash__.remove(items,function (x) {
                        return (shipModeCodes.indexOf(x.fields.ArtCode) !== -1 || discountItemCode.indexOf(x.fields.ArtCode) !== -1  || payterSuerchargeItemCode.indexOf(x.fields.ArtCode) !== -1);
                    });
                    return items;
                }
                return null;
            },
            controlStock(){
                return this.$store.getters.getControlStock;
            },
            mpAvailable:function (){
                return 'mercadopagoCustom' in Vue.options.components;
            },
            redirectAvailable(){
                let pt = this.$store.state.setting.PayTermRow.find(row => row.fields.PayTermCode === this.currentPayTerm);
                if (pt && pt.fields.OppenRedirectEndPoint)
                    return this.$store.getters.getSetting.MercadoPagoMode == 2;
            },
            viewIsLoadingOld:function (){
                //return false;
                for(let cartItem of this.itemsSelected) {
                    let storeItem = this.$store.getters.getItemByCode(cartItem.item.Code);
                    //console.log( 'ST Item AAA',storeItem );
                    if (storeItem && storeItem.isCalculate == false) {
                        this.loadPrices();
                        return true;
                    }
                }
                return false;
            },
            viewIsLoading:function (){
                if(! this.orderTemplate)
                    return true;
                return false;
            },
            show(){
                return true;
                //return this.$store.getters.getCarModal
            },
            customer(){
                return this.$store.getters.getCustomer;
            },
            checkOutReady(){
                return this.$store.getters.getItemCar.length > 0;
            },
            orderTemplate(){
                return  __lodash__.cloneDeep(this.$store.state.carOrderTemplate);
            },
            itemsSelected:function (){
                let items = this.$store.getters.getItemCar;
                let newitems = new Map();
                for(let item of items){
                    if(!newitems.has(item.item.Code))
                        newitems.set(item.item.Code,item);
                    else
                        newitems.get(item.item.Code).cant = newitems.get(item.item.Code).cant + item.cant;
                }
                return Array.from(newitems.values());
            },
            carTotalAmount: function (){
                //this.calculateOrderTotals();
                return this.$store.state.total_in_car;
            },
            payTermFilter(){
                let filter = {};
                let settingPayTerm = this.$store.getters.getPayTerms;
                if(this.$store.state.customer)
                    settingPayTerm.push(this.$store.state.customer.PayTerm);
                filter['Code__in'] = settingPayTerm;
                return filter;
            },
            payTerm(){
                if(this.$store.state.order_PayTerm)
                    return this.$store.state.order_PayTerm;
                if(this.$store.state.customer) {
                    return this.$store.state.customer.PayTerm;
                }
                return null;
            },
            Currency:function () {
                return this.$store.getters.getCurrency;
            }
        };
    }

    getWatch() {
        return  {
            "orderCampaign":function () {
                this.orderCampaignActive = false;
            },
            "$store.getters.getCarAmount" : function (newValue, oldValue){
                this.calculateOrderTotals(true);
            },
            '$store.state.order_PayTerm':function (newValue, oldValue){
                this.calculateOrderTotals(true);
            },
            '$store.state.order_DelAddressCode':function (newValue, oldValue){
                this.calculateOrderTotals();
            },
        };
    }

    getTemplate(){
        return `<div id="carModal" class="row cart-items" v-if="!viewIsLoading">
                  <h5 class="col-12 title">{{tr("Your Purchase")}}</h5>
                  <div class="col-12">
                     <addSOFavModalComponent :from="'car-items'"></addSOFavModalComponent>
                     <template v-if="detailItem">
                        <carModalDetailComponent :item="detailItem" :closeCallback="closeDetail" ></carModalDetailComponent>
                    </template>
                     <template v-if="errors.length>0">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <template v-for="msg_error of errors">
                                <p>{{tr(msg_error)}}</p>
                            </template>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </template>
                     <div class="col-12 px-0 px-sm-3">
                          <div class="stepwizard">
                              <div class="stepwizard-row setup-panel">
                                  <div class="stepwizard-step">
                                      <button @click="navListItems(1)" :class="{'btn': true, 'active': currentStep==1 , 'disabled': currentStep<1}" :disabled="currentStep<1">1</button>
                                      <p>{{tr("Items in Cart")}}</p>
                                  </div>
                                  <div class="stepwizard-step">
                                      <button @click="navListItems(2)" :class="{'btn': true, 'active': currentStep==2 , 'disabled': currentStep<2}" :disabled="currentStep<2">2</button>
                                      <p>{{tr("Delivery Data")}}</p>
                                  </div>
                                  <div class="stepwizard-step">
                                      <button  @click="navListItems(3)" :class="{'btn': true, 'active': currentStep==3 , 'disabled': currentStep<3}" :disabled="currentStep>3">3</button>
                                      <p>{{tr('Result')}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="stepwizard-content row">
                             <div class="col-12 setup-content order-items px-2 px-sm-3" id="step-1" v-if="currentStep==1">
                                 <div class="table-responsive">
                                     <table class="table table-striped">
                                         <thead>
                                             <tr>
                                                 <th scope="col">{{tr("Code")}}</th>
                                                 <th scope="col">{{tr("Item")}}</th>
                                                 <th scope="col">{{tr("Unit")}}</th>
                                                 <th scope="col">{{tr("Price")}} {{showPriceLabel()}}</th>
                                                 <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                 <th scope="col" class="text-right">{{tr("Total")}} {{showPriceLabel()}}</th>
                                                 <th></th>
                                             </tr>
                                         </thead>
                                         <tbody v-if="orderTemplate">
                                            <template v-for="item in getOrderItems">
                                             <template v-if="item.message">
                                               <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                   <td scope="row" >{{item.fields.ArtCode}}</td>
                                                   <td colspan="4">
                                                      <a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>
                                                   </td>
                                                   <td colspan="3">
                                                      {{tr(item.message)}}
                                                   </td>
                                                    <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                      <span class="row"  :title="tr('Delete Element from Car')">
                                                          <i class="fas fa-trash"></i>
                                                      </span>
                                                   </td>
                                                </tr>
                                             </template>
                                             <template v-else>
                                                 <tr :key="'carItem-'+item.fields.ArtCode">
                                                   <th scope="row" >{{item.fields.ArtCode}}</th>
                                                   <td @click="viewDetailItem(item)">
                                                      <a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>
                                                   </td>
                                                   <td scope="row">{{item.fields.Unit}}</td>
                                                   <td scope="row" class="center" >
                                                       <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                {{number_format(item.fields.VATPrice,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                           </template>
                                                           <template v-else>
                                                                {{number_format(item.fields.Price,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                            </template>
                                                       </template>
                                                       <template v-else>
                                                           --
                                                       </template>
                                                   </td>
                                                   <td scope="row" colspan="3" class="center" >
                                                      <div class="form-inline row  justify-content-center">
                                                          <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                              <i class="fas fa-plus-circle waves-effect"></i>
                                                          </label>
                                                          <input :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty" @onfocus="autoResetBlur">
                                                          <label class="sr-only form-control"@click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                              <i class="fas fa-minus-circle waves-effect"></i>
                                                          </label>
                                                       </div>
                                                   </td>
                                                   <td class="text-right">
                                                       <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                {{number_format(item.fields.RowTotal,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                           </template>
                                                           <template v-else>
                                                                {{number_format(item.fields.RowNet,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                            </template>
                                                       </template>
                                                       <template v-else>
                                                           --
                                                       </template>
                                                   </td>
                                                   <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                      <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                          <i class="fas fa-trash"></i>
                                                      </span>
                                                   </td>
                                                 </tr>
                                             </template>
                                            </template>
                                            <template v-if="$store.getters.showTaxInOrder">
                                                <tr v-if="$store.getters.getSetting.ShowPriceWithIVA">
                                                     <td colspan="9" class="text-right" >
                                                          <strong class="mr-2">Sub Total</strong>
                                                          <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                              <strong>$ {{number_format(getOrderField('SubTotal',0),2)}}</strong>
                                                          </template>
                                                          <template v-else>
                                                              --
                                                          </template>
                                                      </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="9" class="text-right">
                                                        <strong class="mr-2">Total IVA</strong>
                                                        <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                            <strong>$ {{number_format(getOrderField('VATTotal',0),2)}}</strong>
                                                        </template>
                                                        <template v-else>
                                                            --
                                                        </template>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="9" class="text-right">
                                                        <strong class="mr-2">Percepciones</strong>
                                                        <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                            <strong>$ {{number_format(getOrderField('TotalTax',0),2)}}</strong>
                                                        </template>
                                                        <template v-else>
                                                            --
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                            <tr v-if="getDiscountCoupon">
                                                <td colspan="9" class="text-left text-md-right">
                                                  <template >
                                                    <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                  </template>
                                                </td>
                                            </tr>
                                            <tr v-if="getShipCost">
                                                <td colspan="9" class="text-left text-md-right">
                                                  <template>
                                                    <strong>{{tr("Ship Cost")}}:$ {{getShipCost}}</strong> 
                                                  </template>
                                                </td>
                                            </tr>
                                            <tr v-if="getPayTermSurcharge">
                                                <td colspan="9" class="text-left text-md-right">
                                                     <strong>{{getPayTermSurchargeLable()}}: $ {{getPayTermSurcharge}} </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="9" class="text-left text-md-right">
                                                    <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                        <strong class="mr-2">Total (IVA INC)</strong>
                                                   </template>
                                                   <template v-else>
                                                        <strong class="mr-2">Total (IVA EXC)</strong>
                                                    </template>
                                                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                       <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                            <strong>$ {{number_format(getOrderField('Total',0), 2)}}</strong> <small v-if="Currency"> {{Currency}}</small>  
                                                       </template>
                                                       <template v-else>
                                                            <strong>$ {{number_format(getOrderField('SubTotal',0), 2)}}</strong> <small v-if="Currency"> {{Currency}}</small>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        --
                                                    </template>
                                                </td>
                                            </tr>
                                         </tbody>
                                     </table>
                                     <div class="row mt-2 mx-0">
                                          <div class="col-12 col-md-6 text-center text-md-left">
                                              <button key="btn-1-step-1" @click="goToShop()" class="btn btn-secondary mb-2">
                                                  <i class="fas fa-cart-plus mr-1"></i>
                                                  {{tr('Continue Shopping')}}
                                              </button>
                                              <button key="btn-4-step-1" @click="clearCar()" class="btn btn-secondary mb-2">
                                                  <i class="fas fa-trash mr-1"></i>
                                                  {{tr('Clear')}}
                                              </button>
                                          </div>
                                          <div class="col-12 col-md-6 order-first order-md-last text-center text-md-right">
                                              <button key="brn-2-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-secondary mb-2" data-toggle="modal" data-target="#addSOFavModal">
                                                  <i class="fas fa-star mr-1"></i>
                                                  {{tr('Add To Favorite Orders')}}
                                              </button>
                                              <button key="brn-3-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-success mb-2 nextBtn" type="button" @click="nextStep($event)">
                                                  <span>{{tr('Delivery Data')}}</span>
                                                  <i class="fas fa-arrow-right ml-1"></i>
                                              </button>
                                          </div>
                                     </div>
                                 </div>
                             </div>
                             <div class="col-12 setup-content" id="step-2" v-if="currentStep==2">
                                  <div class="row">
                                    <carCenterCostList />
                                  </div>
                                  <div class="row">
                                    <carDeliveryList @deliveryselected="calculateOrderTotals()" />
                                  </div>
                                  <div class="col-12">
                                      <div class="form-row m-0 mb-3 bordered">
                                         <h6 class="col-12 align-items-center d-flex"><i class="delivery-icon fas fa-credit-card fa-2x mr-3" style="font-size: 1.6em;"></i>{{tr('Seleccione un método de pago')}}</h6>
                                         <div class="form-group col-6 px-2">
                                             <label class="col-form-label">{{tr("PayTerm")}}</label>
                                             <select  class="form-control" @change="updateSelectValues('PayTerm')">
                                                 <template v-for="payterm of payTerms">
                                                        <template v-if="$store.state.setting.AllowNotOnlyPayments==true">
                                                            <option :value="payterm.fields.PayTermCode" :selected="payterm.fields.RequireOnlinePayment">{{payterm.fields.PayTermName}}</option>
                                                        </template>
                                                        <template v-else>
                                                            <template v-if="payterm.fields.RequireOnlinePayment">
                                                                <option :value="payterm.fields.PayTermCode" :selected="payterm.fields.RequireOnlinePayment">{{payterm.fields.PayTermName}}</option>
                                                            </template>
                                                        </template>
                                                    </template>
                                             </select> 
                                         </div>
                                         <div class="form-group col-6 px-2">
                                              <label class="col-form-label">{{tr("Coupon")}}</label>
                                              <input class="form-control" v-model="orderCampaign" v-on:change="calculateDiscount" >
                                         </div>
                                         <div class="form-group col-6 px-2">
                                            <label class="col-12 col-form-label">{{tr("Comment")}}"</label>
                                            <div class="col-sm-12">
                                              <textarea class="form-control" rows="3" v-model="orderComment" v-on:change="updateSelectValues('Comment')"></textarea>
                                            </div>
                                         </div>
                                     </div>
                                  </div>
                                  <div class="col-12">
                                      <div class="text-right align-self-end">
                                          <button key="btn-1-step-2" class="btn btn-secondary prevBtn float-left mb-2" type="button" @click="prevStep()">
                                                <i class="fas fa-arrow-left"></i>
                                                <span>{{tr('Back to Items in Cart')}}</span>
                                          </button>
                                          <button key="btn-2-step-2" @click="payAndClose($event)" v-bind:class="{btn:true, 'btn-success':true, disabled:itemsSelected.length==0}">{{tr('Checkout')}}</button>
                                      </div>
                                  </div>
                             </div>
                             <div class="col-12 setup-content my-4" id="step-3" v-if="currentStep==3">
                                 <div class="col-12">
                                     <template v-if="status=='loading'">
                                         <div class="">
                                             <h5 class="text-center">{{tr("Your Order is processing")}}.{{tr("Please Wait")}}.</h5>
                                         </div>
                                     </template>
                                     <template v-if="status=='failure'">
                                         <h5 class="text-center">{{tr("The Sales Order can't be created")}}.</h5>
                                         <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message px-1">
                                             <template v-for="msg_error of result.error" >
                                                <oppenErrorDisplay :errorData="msg_error" :updateCallback="deleteItemFromCar" />
                                             </template>
                                         </div>
                                         <button class="btn btn-secondary prevBtn float-left" type="button" @click="navListItems(1)">
                                                <i class="fas fa-arrow-left"></i>
                                                <span>{{tr('Back to Items in Cart')}}</span>
                                         </button>
                                     </template>
                                     <template  v-if="status=='result'">
                                         <h5 class="text-center">{{ tr([successMessage,result.so.fields.SerNr]) }}.</h5>
                                         <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message my-3 px-1 pt-2">
                                             <h5 class="text-center">{{tr("Information")}}:</h5>
                                             <p v-for="msg_error of result.error" class="text-center">{{tr(msg_error)}}</p>
                                         </div>
                                         <template v-if="(mpAvailable || redirectAvailable) && mpdata && mpdata.amount">
                                            <div class="col-12 col-md-6 offset-md-3 alert alert-warning px-1" role="alert" v-if="paymentMsg">
                                                <template v-if="Array.isArray(paymentMsg)">
                                                     <p v-for="msg_error of paymentMsg" class="text-center">{{tr(msg_error.description.charAt(0).toUpperCase() + msg_error.description.slice(1))}}</p>
                                                </template>
                                                <template v-else>
                                                    <p class="text-center">{{tr(paymentMsg)}}</p>
                                                </template>
                                            </div>
                                            <template v-if="paymentIsProcess">
                                                <div class="col-12 col-md-6 offset-md-3 alert alert-success text-center" role="alert">
                                                    <icon name="regular/check-circle" scale="5" class="col-6"/>
                                                    <h4 class="col-12 text-center mt-2">{{tr("The payment was approved successfully")}}</h4>
                                                    <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                                </div>
                                            </template>
                                            <div class="row justify-content-center mt-4"  v-if="doOnlinePayment">
                                                <template v-if="$store.getters.getSetting.MercadoPagoMode==1">
                                                    <mercadopagoFormCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoFormCustom>
                                                </template>
                                                <template v-else-if='$store.getters.getSetting.MercadoPagoMode==0'>
                                                    <mercadopagoCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoCustom>
                                                </template>
                                                <template v-else-if='$store.getters.getSetting.MercadoPagoMode==2'>
                                                    <redirectPaymentComponent :SO='result.so.fields.SerNr' :PayTermObj='getPayTermObj()'/>
                                                </template>
                                            </div>
                                        </template>
                                        <div class="row justify-content-center mt-4">
                                           <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                        </div>
                                    </template>
                                 </div>
                             </div>
                          </div>
                      </div>
                  </div>
              </div>`;
    }

}

CarComponent.registerComponent();
